// NUMBERS SECTION
document.addEventListener("DOMContentLoaded", function() {
  $(window).on( "scroll", function () {
    if ($('.numbersSection').length > 0) {
      if ($(this).width() > 767) {
        if ($(this).scrollTop() > $('.numbersSection').offset().top - $(this).outerHeight()) {

        }
      } else {
        if ($(this).scrollTop() > $('.numbersSection').offset().top - $(this).outerHeight()) {

        }
      }
    }
  });

  $(function () {
    // INIT ODOMETER
    if ($('.numbersSection').length > 0) {
      generateNumbersButton();
      shuffleNumbers();

      window.addEventListener('scroll', showNumbersGeneratingWhenScroll);
      function showNumbersGeneratingWhenScroll() {
        let numbersSection = document.querySelector('.numbersSection');
        let numbersSectionTopBorder =  numbersSection.getBoundingClientRect().top - window.innerHeight;
        // console.log(numbersSectionTopBorder);
        if (numbersSectionTopBorder < -300) {
          generateNumberWithOdometer();
          removeScrollEventForGeneratingNb();
        }
      }

      function removeScrollEventForGeneratingNb() {
        window.removeEventListener("scroll", showNumbersGeneratingWhenScroll);
      }

      function generateNumberWithOdometer() {
        const listItems = document.querySelectorAll('.numbers ul li');
        let time = 100;
        // console.log(listItems.length)
        for (let i = 1; i < (listItems.length + 1); i++) {
          time += 200;
          if (i === 1) time = 100;
          setTimeout(function () {
            updateOdometer(`#number${i}`);
          }, time);
        }
      }

      //numbers zwijanie
      $(".moreInfoBtn").click(() => showMoreLessNumbers());

      function generateNumbersButton() {
        const numbersSection = document.querySelector('.numbersSection');
        const btnWrapper = document.createElement('div');
        btnWrapper.classList.add('btnWrapper');
        const btnShowMoreNumbers = document.createElement('button');
        btnShowMoreNumbers.classList.add('moreInfoBtn');
        btnShowMoreNumbers.textContent = 'Pokaż więcej';
        btnWrapper.append(btnShowMoreNumbers);
        numbersSection.append(btnWrapper);
      }

      function showMoreLessNumbers() {
        const btn = $(".moreInfoBtn")
        if (btn.hasClass('active')) {
          btn.text('Pokaż więcej');
          $(".showMoreNb").hide(1000);
          btn.removeClass('active')
          return
        }
        $(".showMoreNb").first().show("fast", function showNext() {
          $(this).next(".showMoreNb").show("fast", showNext);
        });
        btn.addClass('active')
        btn.text('Pokaż mniej');
      }

      function shuffleNumbers() {
        const listItems = document.querySelectorAll('.numbers ul li');
        const arrOfListItems = Array.from(listItems, listItem => listItem);
        shuffle(arrOfListItems);
        prepareOdometerStructure();
        initOdometerOnNumbers();
        function prepareOdometerStructure() {
          $('.numbers ul li').remove();
          $(arrOfListItems).each(function (i) {
            let item = $(this);
            if (i > 2) {
              item.addClass('showMoreNb');
            }
            const itemNumberValue = item.find('.txtNumber');
            itemNumberValue.attr('id', `number${i + 1}`);
            $('.numbers ul').append(item);
          })
        }
        function initOdometerOnNumbers() {
          for (let i = 1; i < (listItems.length + 1); i++) {
            initOdometer(`#number${i}`);
          }
        }
      }

      function shuffle(array) {
        let currentIndex = array.length, randomIndex;
        while (currentIndex != 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
        return array;
      }
    }
  });

  function initOdometer(selector) {
    var $container = $(selector).eq(0);

    var odometer = new Odometer({
      el: $container.get(0),
      format: '',
      duration: 3000
    });
  }

  function updateOdometer(selector) {
    var value = $(selector).data('val');
    $(selector).text(value);
  }
});